<template>
  <div class="drug-view ly-wrap">
    <drug-nav selected="1"></drug-nav>
    <div class="error-wrap" v-if="isError">
      <div class="error-img"></div>
      <p>{{ errorMsg || '系统异常' }} </p>
    </div>

    <div v-else class="ly-center">
      <div class="drug-view-wrap">
        <div class="nav-title"><i class="water-icon"></i>PAC投加系统 <span>数据刷新时间：{{ loadTime | formatData }}</span></div>
        <div class="drug-view-bg">
          <!--png水管-->
          <div class="png-wrap">
            <div class="ab po-1 pg-y-1"></div>
            <div class="ab po-3 pg-y-5"></div>
            <div class="ab po-4 pg-y-2"></div>
            <div class="ab po-2 pg-y-11"></div>
            <div class="ab po-5 pg-y-8"></div>
            <div class="ab po-6 pg-y-1"></div>
            <div class="ab po-7 pg-y-1"></div>
            <div class="ab po-10 pg-y-5"></div>
            <div class="ab po-11 pg-y-2"></div>
            <div class="ab po-8 pg-y-11"></div>
            <div class="ab po-9 pg-y-11"></div>
            <div class="ab po-12 pg-y-8"></div>
            <div class="ab po-13 pg-y-1"></div>
            <div class="ab po-14 pg-y-9"></div>
            <div class="ab po-15 pg-y-1"></div>
            <div class="ab po-17 pg-y-1"></div>
            <div class="ab po-18 pg-y-10"></div>
            <div class="ab po-19 pg-y-2"></div>
            <div class="ab po-16 pg-y-11"></div>
            <div class="ab po-20 pg-y-12"></div>
          </div>
          <!--工艺组件-->
          <div class="parts-wrap">
            <div class="ab zo-1 zj-z7"></div>
            <div class="ab zo-2 zj-z7"></div>
          </div>
          <!--仪器仪表-->
          <div class="meter-wrap">
            <div class="ab mo-1 yq-y9"></div>
            <div class="ab mo-2 yq-y9"></div>
            <div class="ab mo-3 yq-y9">
              <!--              <div class="ab red"></div>-->
            </div>
            <div class="ab mo-4 yq-y9"></div>
            <div class="ab mo-5 yq-y9"></div>
            <div class="ab mo-6 yq-y8" @click="handleControl('1号泵',pacData.PAC_泵1运行状态.data,1,pacData.PAC_泵1启停.id)">
              <div class="ab red" v-show="pacData.PAC_泵1运行状态.data==0"
                   @click="handleControl('1号泵',pacData.PAC_泵1运行状态.data,1,pacData.PAC_泵1启停.id)"></div>
            </div>
            <div class="ab mo-7 yq-y8" @click="handleControl('2号泵',pacData.PAC_泵2运行状态.data,1,pacData.PAC_泵2启停.id)">
              <div class="ab red" v-show="pacData.PAC_泵2运行状态.data==0"
                   @click="handleControl('2号泵',pacData.PAC_泵2运行状态.data,1,pacData.PAC_泵2启停.id)"></div>
            </div>
            <div class="ab mo-8 yq-y8" @click="handleControl('3号泵',pacData.PAC_泵3运行状态.data,1,pacData.PAC_泵3启停.id)">
              <div class="ab red" v-show="pacData.PAC_泵3运行状态.data==0"
                   @click="handleControl('3号泵',pacData.PAC_泵3运行状态.data,1,pacData.PAC_泵3启停.id)"></div>
            </div>
            <div class="ab mo-9 yq-y9 rt-rt">
              <!--              <div class="ab red"></div>-->
            </div>
            <div class="ab mo-10 yq-y9 rt-rt"></div>
          </div>
          <!--文字-->
          <div class="txt-wrap">
            <div class="ab to-1 white-bg">
              <p :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">
                {{ pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动' }}</p>
              <div class="ab sjx-wt-dn" style="left: 30%;"></div>
            </div>
            <div class="ab to-2 white-bg">
              <p class="value">1号电动阀</p>
              <div class="ab sjx-wt-up"></div>
            </div>
            <div class="ab to-3 white-bg">
              <p :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">
                {{ pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动' }}</p>
              <div class="ab sjx-wt-dn" style="left: 30%;"></div>
            </div>
            <div class="ab to-4 white-bg">
              <p class="value">2号电动阀</p>
              <div class="ab sjx-wt-up"></div>
            </div>
            <div class="ab to-5 big-bg">
              <div class="big-title">1号罐</div>
              <div class="big-txt">
                <p>液位：<span>{{ pacData.PAC_液位1.data }}m</span></p>
                <p class="green">正常</p>
              </div>
              <div class="ab sjx-wt-dn"></div>
            </div>
            <div class="ab to-6 big-bg">
              <div class="big-title">2号罐</div>
              <div class="big-txt">
                <p>液位：<span>{{ pacData.PAC_液位2.data }}m</span></p>
                <p class="green">正常</p>
              </div>
              <div class="ab sjx-wt-dn"></div>
            </div>
            <div class="ab to-7 white-bg">
              <p :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">
                {{ pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动' }}</p>
              <div class="ab sjx-wt-dn" style="left: 30%;"></div>
            </div>
            <div class="ab to-8 white-bg">
              <p class="value">3号电动阀</p>
              <div class="ab sjx-wt-up"></div>
            </div>
            <div class="ab to-9 white-bg">
              <p :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">
                {{ pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动' }}</p>
              <div class="ab sjx-wt-dn" style="left: 30%;"></div>
            </div>
            <div class="ab to-10 white-bg">
              <p class="value">4号电动阀</p>
              <div class="ab sjx-wt-up"></div>
            </div>
            <div class="ab to-11 white-bg">
              <p :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">
                {{ pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动' }}</p>
              <div class="ab sjx-wt-dn" style="left: 30%;"></div>
            </div>
            <div class="ab to-12 white-bg">
              <p class="value">5号电动阀</p>
              <div class="ab sjx-wt-up"></div>
            </div>
            <div class="ab to-13 big-bg">
              <div class="big-title">1号计量泵</div>
              <div class="big-txt">
                <p>频率：<span>{{ pacData.PAC_泵1频率.data }}Hz</span></p>
                <p>流量：<span>{{ pacData.PAC_泵1流量.data }}m³/h</span></p>
                <p><span
                    :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">{{
                    pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动'
                  }}</span><span
                    class="green" style="margin-left: 0.1rem">正常</span></p>
              </div>
              <div class="ab sjx-wt-dn"></div>
            </div>
            <div class="ab to-14 big-bg">
              <div class="big-title">2号计量泵</div>
              <div class="big-txt">
                <p>频率：<span>{{ pacData.PAC_泵2频率.data }}Hz</span></p>
                <p>流量：<span>{{ pacData.PAC_泵2流量.data }}m³/h</span></p>
                <p><span
                    :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">{{
                    pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动'
                  }}</span><span
                    class="green" style="margin-left: 0.1rem">正常</span></p>
              </div>
              <div class="ab sjx-wt-dn"></div>
            </div>
            <div class="ab to-15 big-bg">
              <div class="big-title">3号计量泵</div>
              <div class="big-txt">
                <p>频率：<span>{{ pacData.PAC_泵3频率.data }}Hz</span></p>
                <p>流量：<span>{{ pacData.PAC_泵3流量.data }}m³/h</span></p>
                <p><span
                    :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">{{
                    pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动'
                  }}</span><span
                    class="green" style="margin-left: 0.1rem">正常</span></p>
              </div>
              <div class="ab sjx-wt-dn"></div>
            </div>
            <div class="ab to-16 white-bg">
              <p>原水流量：</p>
              <p class="value">{{ pacData.FT0001.data }}m³/h</p>
              <p>原水浊度：</p>
              <p class="value">{{ pacData.SS0001.data }}NTU</p>
            </div>
            <div class="ab to-17 white-bg">
              <p :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">
                {{ pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动' }}</p>
              <div class="ab sjx-wt-lf" style="top: 20%"></div>
            </div>
            <div class="ab to-18 white-bg">
              <p :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">
                {{ pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动' }}</p>
              <div class="ab sjx-wt-lf" style="top: 20%"></div>
            </div>
            <div class="ab to-19 big-bg">
              <div class="big-title">搅拌模式：<span
                  :class="[pacData.PAC搅拌模式.data==0?'value':'green']">{{
                  pacData.PAC搅拌模式.data == 0 ? '手动' : '自动'
                }}</span>
              </div>
              <div class="df-btn" @click="handleModalControl('搅拌',2,pacData.PAC搅拌模式.data,pacData.PAC搅拌模式.id)">设置</div>
            </div>
            <div class="ab to-20 big-bg">
              <div class="big-title">控制模式：<span
                  :class="[pacData.PAC泵手自动模式.data==0?'value':'green']">{{
                  pacData.PAC泵手自动模式.data == 0 ? '手动' : '自动'
                }}</span>
              </div>
              <div class="df-btn" @click="handleModalControl('控制',3,pacData.PAC泵手自动模式.data,pacData.PAC泵手自动模式.id)">设置
              </div>
            </div>
            <div class="ab to-21">
              <p style="padding-top: 0.25rem">参数</p>
              <p>设置</p>
            </div>
          </div>
        </div>
      </div>

      <div class="drug-view-wrap">
        <div class="nav-title"><i class="water-icon"></i>1号CLO2加药系统
          <div class="df-btn" style="float: right;"
               @click="handleControl('1号CLO2加药系统',pacData.N1_泵启停.data,4,pacData.N1_泵启停.id)">启停系统
          </div>
        </div>
        <div class="medicine-view-bg">
          <div class="md-left">
            <div class="md-table">
              <div class="td-line" style="height: 0.1rem"></div>
              <div class="td-line">
                <div class="td-name ">设备负压</div>
                <div class="td-value">{{ pacData.N1_设备负压.data }}</div>
                <div class="td-unit ">cm</div>
              </div>
              <div class="td-line">
                <div class="td-name ">残留二氧化氢</div>
                <div class="td-value">{{ pacData.CL4001.data }}</div>
                <div class="td-unit ">ppm</div>
              </div>
              <div class="td-line">
                <div class="td-name ">反应温度1</div>
                <div class="td-value">{{ pacData.N1_反应温度1.data }}</div>
                <div class="td-unit ">℃</div>
              </div>
              <div class="td-line">
                <div class="td-name ">反应温度2</div>
                <div class="td-value">{{ pacData.N1_反应温度2.data }}</div>
                <div class="td-unit ">℃</div>
              </div>
              <div class="td-line">
                <div class="td-name ">气体温度</div>
                <div class="td-value">{{ pacData.N1_气体温度.data }}</div>
                <div class="td-unit ">℃</div>
              </div>
              <div class="td-line">
                <div class="td-name ">二氧化氯投加量</div>
                <div class="td-value">{{ pacData.N1_二氧化氯投加量.data }}</div>
                <div class="td-unit ">g/m³</div>
              </div>
              <div class="td-line">
                <div class="td-name ">原水流量</div>
                <div class="td-value">{{ pacData.FT0001.data }}</div>
                <div class="td-unit ">m³/h</div>
              </div>
              <div class="td-line">
                <div class="td-name ">氯酸钠流量</div>
                <div class="td-value">{{ pacData.N1_钠泵流量.data }}</div>
                <div class="td-unit ">L/h</div>
              </div>
              <div class="td-line">
                <div class="td-name ">盐酸流量</div>
                <div class="td-value">{{ pacData.N1_酸泵流量.data }}</div>
                <div class="td-unit ">L/h</div>
              </div>
            </div>
          </div>
          <div class="md-right">
            <div class="md-light-box">
              <div class="light-item">
                <div class="img" :class="[pacData.N1_泵启停.data==0?'red':'']"></div>
                <p>泵停止</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>水欠压</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>缺水</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>缺氯酸钠</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>缺硫酸</p>
              </div>
              <div class="light-item">
                <div class="img" :class="[pacData.N1_加热1.data==0?'red':'']"></div>
                <p>加热1停止</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>高温1</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>高温2</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>超温1</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>超温2</p>
              </div>
              <div class="light-item">
                <div class="img" :class="[pacData.N1_加热2.data==0?'red':'']"></div>
                <p>加热2停止</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>负压报警</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>反应1PT100故障</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>反应2PT100故障</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>气体PT100故障</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="drug-view-wrap">
        <div class="nav-title"><i class="water-icon"></i>2号CLO2加药系统
          <div class="df-btn" style="float: right;"
               @click="handleControl('2号CLO2加药系统',pacData.N2_泵启停.data,4,pacData.N2_泵启停.id)">启停系统
          </div>
        </div>
        <div class="medicine-view-bg">
          <div class="md-left">
            <div class="md-table">
              <div class="td-line" style="height: 0.1rem"></div>
              <div class="td-line">
                <div class="td-name ">设备负压</div>
                <div class="td-value">{{ pacData.N2_设备负压.data }}</div>
                <div class="td-unit ">cm</div>
              </div>
              <div class="td-line">
                <div class="td-name ">残留二氧化氢</div>
                <div class="td-value">{{ pacData.CL4001.data }}</div>
                <div class="td-unit ">ppm</div>
              </div>
              <div class="td-line">
                <div class="td-name ">反应温度1</div>
                <div class="td-value">{{ pacData.N2_反应温度1.data }}</div>
                <div class="td-unit ">℃</div>
              </div>
              <div class="td-line">
                <div class="td-name ">反应温度2</div>
                <div class="td-value">{{ pacData.N2_反应温度2.data }}</div>
                <div class="td-unit ">℃</div>
              </div>
              <div class="td-line">
                <div class="td-name ">气体温度</div>
                <div class="td-value">{{ pacData.N2_气体温度.data }}</div>
                <div class="td-unit ">℃</div>
              </div>
              <div class="td-line">
                <div class="td-name ">二氧化氯投加量</div>
                <div class="td-value">{{ pacData.N2_二氧化氯投加量.data }}</div>
                <div class="td-unit ">g/m³</div>
              </div>
              <div class="td-line">
                <div class="td-name ">原水流量</div>
                <div class="td-value">{{ pacData.FT0001.data }}</div>
                <div class="td-unit ">m³/h</div>
              </div>
              <div class="td-line">
                <div class="td-name ">氯酸钠流量</div>
                <div class="td-value">{{ pacData.N2_钠泵流量.data }}</div>
                <div class="td-unit ">L/h</div>
              </div>
              <div class="td-line">
                <div class="td-name ">盐酸流量</div>
                <div class="td-value">{{ pacData.N2_酸泵流量.data }}</div>
                <div class="td-unit ">L/h</div>
              </div>
            </div>
          </div>
          <div class="md-right">
            <div class="md-light-box">
              <div class="light-item">
                <div class="img" :class="[pacData.N2_泵启停.data==0?'red':'']"></div>
                <p>泵停止</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>水欠压</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>缺水</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>缺氯酸钠</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>缺硫酸</p>
              </div>
              <div class="light-item">
                <div class="img" :class="[pacData.N2_加热1.data==0?'red':'']"></div>
                <p>加热1停止</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>高温1</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>高温2</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>超温1</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>超温2</p>
              </div>
              <div class="light-item">
                <div class="img" :class="[pacData.N2_加热2.data==0?'red':'']"></div>
                <p>加热2停止</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>负压报警</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>反应1PT100故障</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>反应2PT100故障</p>
              </div>
              <div class="light-item">
                <div class="img"></div>
                <p>气体PT100故障</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-copyright">- 技术支持：云昇新控 -</div>

  </div>
</template>

<script>

import drugNav from './modules/DrugNav.vue'
import {postDrugView, postWaterVariableData} from '../../api/api.js'
import moment from "moment";
import {getSelectWater, getUserInfo} from "../../utils/auth";
import store from "../../store";
import {MessageBox, Toast} from "mint-ui";

export default {
  name: 'WaterView',
  components: {drugNav},
  filters: {
    formatData(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {
    pacData() {
      return this.$store.state.sundries.drugViewData.data
    },
    loadTime() {
      return this.$store.state.sundries.drugViewData.collectTime
    }
  },
  data() {
    return {
      isError: false,
      waterId: null,
    }
  },
  mounted() {
    this.loadBase()
  },
  created() {
  },
  methods: {
    async loadBase() {
      const userInfo = await getUserInfo()
      const selectWater = await getSelectWater()
      this.waterId = selectWater.id
      const body = {
        // waterId: '1384330169079689218',
        waterId: selectWater.id,
        userId: userInfo.id
      }
      postDrugView(body).then(res => {
        this.isError = false
        if (res.data.success) {
          store.dispatch('DRUG_APP_PAC', res.data.result)
        } else {
          this.isError = true
          this.errorMsg = res.data.message || '异常'
        }
      })

    },
    // 控制模式切换
    handleModalControl(name, typeCode, state, variableId) {
      let data = 0
      let tips = ''
      if (state == 0) {
        tips = '自动'
        data = 1
      } else {
        tips = '手动'
      }
      // 是否更改控制模式为 手动/自动
      MessageBox.close()
      MessageBox.confirm('是否更改' + name + '模式为' + tips + '？').then(action => {
        console.log(action)
        if (action == 'confirm') {
          this.postData(data, state, 2, typeCode, variableId, this.waterId)
        }
      }).catch((err) => {
        console.log(err)
      });
    },
    // 统一控制
    handleControl(name, state, typeCode, variableId) {
      // 判断控制模式
      if (this.pacData.PAC泵手自动模式 == 1) {
        Toast('当前是自动模式，请先更改控制模式为手动！');
        return
      }
      let data = 0
      let tips = ''
      if (state == 0) {
        tips = '启动'
        data = 1
      } else {
        tips = '停止'
      }
      MessageBox.close()
      MessageBox.confirm('是否' + tips + name + '？').then(action => {
        this.postData(data, state, 2, typeCode, variableId, this.waterId)
      }).catch((err) => {
        console.log(err)
      });
    },
    // 统一的脉冲请求 第一次
    firstPost(data, dataBefore, logType, typeCode, variableId, waterId) {
      const body = {
        data: data,
        dataBefore: dataBefore,
        logType: logType,
        typeCode: typeCode,
        variableId: variableId,
        waterId: waterId
      }
      postWaterVariableData(body)
    },
    // 统一的控制请求
    postData(data, dataBefore, logType, typeCode, variableId, waterId) {
      const body = {
        data: data,
        dataBefore: dataBefore,
        logType: logType,
        typeCode: typeCode,
        variableId: variableId,
        waterId: waterId
      }
      postWaterVariableData(body).then(res => {
        this.loadBase()
        Toast('操作成功！');
      })
    },
  },
  destroyed: function () { // 离开页面生命周期函数
  },
}
</script>

<style scoped lang="less">
.drug-view {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
}

@import '../../assets/less/drugView.less';
@import '../../assets/less/view.less';
</style>

